export class Buildings {
  static setup() {
    $('#building_icon_name').change(function() {
      const icon_class = $('#building_icon_name :selected').val();
      $('#building-icon-display').removeClass().addClass('fa fa-4x ' + icon_class);
    });

    $('#building_icon_color').change(function() {
      const icon_color = $('#building_icon_color :selected').val();
      $('#building-icon-display').attr('style', 'color: ' + icon_color + ';');
    });
  }
}
