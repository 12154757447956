import { Readings } from '../src/readings';
import { Buildings } from '../src/buildings';
import Chartkick from 'chartkick';
import Chart from 'chart.js';
import '../src/readings.scss';

Chartkick.use(Chart);

$(document).ready(function() {
  Readings.setup();
  Buildings.setup();
});
